/* jshint esversion: 6 */
/* jshint esversion: 8 */
/* jshint esversion: 11 */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ApiGet, ApiPost } from "../../helpers/API/ApiData";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import "../Shop/shop.scss";
import * as authUtil from "../../utils/auth.util";
import * as userUtil from "../../utils/user.util";
import LoadingBalls from "../../Assets/LoadingBalls";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Country from "../js/countries/Country";
import Userlogin from "../js/Login/Login";
import UserSignup from "../js/Login/Signup";
import Forgotpassword from "../js/Login/Forgotpassword";

export default function Shop() {
  const [forgotModal, setForgotModal] = useState(false);
  const history = useNavigate();
  const [Idc, setIdc] = useState();
  const [loading, setLoading] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [signupErrors, setSignupErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [signupFormData, setSignupFormData] = useState({});
  const [productData, setProductData] = useState();
  const [countryInit, setCountryInit] = useState();
  const [productImage, setProductImage] = useState([]);
  const [passwordSeen, setPasswordSeen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [shopDetails, setShopDetails] = useState([]);
  const handlePasswordSeen = () => {
    setPasswordSeen(!passwordSeen);
  };

  const handleLoginCheck = (id) => {
    console.log(authUtil.isLoggedIn());
    if (authUtil.isLoggedIn() === false) {
      setLoginModal(true);
    } else {
      history(`/cart/${id}`);
    }
  };

  const validate = () => {
    let error = [];
    let formIsValid = true;
    if (!formData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    }
    if (!formData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setErrors(error);
    return formIsValid;
  };
  const signupValidate = () => {
    let error = [];
    let formIsValid = true;
    if (!signupFormData.email) {
      formIsValid = false;
      error["email"] = "Please Enter Email";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(signupFormData.email)
    ) {
      formIsValid = false;
      error["email"] = "Please Enter proper Email";
    }

    if (!signupFormData.firstName) {
      formIsValid = false;
      error["firstName"] = "Please Enter FirstName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.firstName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.lastName) {
      formIsValid = false;
      error["lastName"] = "Please Enter LastName";
    } else if (!/^[aA-zZ\s]+$/.test(signupFormData.lastName)) {
      formIsValid = false;
      error["firstName"] = "only alphabetic character are allowd";
    }
    if (!signupFormData.password) {
      formIsValid = false;
      error["password"] = "Please Enter Password";
    }
    setSignupErrors(error);
    return formIsValid;
  };
  const handleSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (validate()) {
        const data = {
          email: formData.email,
          password: formData.password,
        };
        setLoading(true);
        await ApiPost("users/login", data)
          .then((res) => {
            if (res.data.message === "Successfully logged in.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setLoginModal(false);
              window.location.reload();
            } else if (res.msg) {
              setLoading(false);
              setErrors({ user: "User does not exist." });
            } else {
              setLoading(false);
              setErrors({ user: "Please check username or password" });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleSignupSubmit = async (event) => {
    if ((event && event.keyCode === 13) || (event && event.type === "click")) {
      if (signupValidate()) {
        const data = {
          firstName: signupFormData.firstName,
          lastName: signupFormData.lastName,
          email: signupFormData.email,
          password: signupFormData.password,
          contact: signupFormData.contact,
          country: countryInit,
          countryCode: Idc,
        };
        let err = [];

        setLoading(true);
        await ApiPost("users/signup", data)
          .then((res) => {
            if (res?.data?.data === "Email has been already registered.") {
              err["error"] = res.data.data;
              setSignupErrors(err);
            }
            if (res.data.message === "Record Sucessfully Inserted.") {
              setLoading(false);
              authUtil.setToken(res.data.data.token);
              userUtil.setUserInfo(res.data.data);
              setSignupModal(false);
              window.location.reload();
            } else {
              setLoading(false);
              setErrors({ user: res.msg });
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log("err", err);
          });
      }
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSignupChange = (e) => {
    let { name, value } = e.target;
    setSignupFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getShopDetails = async () => {
    await ApiGet("shop/shopdetails")
      .then((res) => {
        console.log("shopDetails", res.data);
        setShopDetails(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const getAllProduct = async () => {
      setLoader(true);
      await ApiGet("product/get-all-products")
        .then((res) => {
          setLoader(false);
          console.log(res.data.data);
          setProductData(res.data.data);
        })
        .catch((err) => {
          setLoader(false);
          console.log("err", err);
        });
    };
    getAllProduct();
    getProductImage();
    getShopDetails();
  }, []);

  const getProductImage = async () => {
    await ApiGet("admin/get-all-user-dashboard")
      .then((res) => {
        setProductImage(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <>
          <Header />
        </>
        <div className="flex">
          <>
            <Sidebar />
          </>
          <main className="s-layout__content">
            <div className="shop-main-box bg-white">
              <div className="dashboard-banner" style={{ marginTop: "100px" }}>
              </div>
              <section style={{ fontFamily: "Arial, sansSerif", padding: "20px", backgroundColor: "#f0f2f5" }}>
                <div className="px-2">
                  <h1 style={{ textAlign: "left", color: "#1c1e21", fontSize: "28px" }}>Welcome to</h1>
                  <div className="recently-opened" style={{ marginTop: "20px", backgroundColor: "white", borderRadius: "8px", padding: "20px" }}>
                    <div className="header" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <h2 style={{ fontSize: "20px", color: "#1c1e21" }}>Recently Opened</h2>
                      <button className="view-all" style={{ textDecoration: "none", fontSize: "14px", color: "#1877f2", background: "none", border: "none", cursor: "pointer" }}>View all Groups</button>
                    </div>

                    {/* Group Cards */}
                    <div className="group-cards" style={{ display: "flex", marginTop: "20px", justifyContent: "space-between" }}>
                      <div className="group-card" style={{
                        width: "30%",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Add smooth transitions
                        cursor: "pointer",
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"} // Scale up on hover
                        onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale
                      >
                        <img src="/Assets/Images/7thCenturyAffiliatesP.png" alt="7th Century Music Affiliates"
                          style={{ width: "100%", borderRadius: "8px", transition: "transform 0.3s ease" }} />
                        <p style={{ marginTop: "10px", fontWeight: "bold" }}>7th Century Music Affiliates</p>
                      </div>

                      <div className="group-card" style={{
                        width: "30%",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Add smooth transitions
                        cursor: "pointer",
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                        onMouseEnter={(e) => e.currentTarget.style.transform = "scale(1.05)"} // Scale up on hover
                        onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale
                      >
                        <img src="/Assets/Images/arti.png" alt="7th Century Music Artists"
                          style={{ width: "100%", borderRadius: "8px", transition: "transform 0.3s ease" }} />
                        <p style={{ marginTop: "10px", fontWeight: "bold" }}>7th Century Music Artists</p>
                      </div>

                      <div className="group-card" style={{
                        width: "30%",
                        transition: "transform 0.3s ease, box-shadow 0.3s ease", // Add smooth transitions
                        cursor: "pointer",
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow = "0px 8px 15px rgba(0, 0, 0, 0.15)"; // Add shadow on hover
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "none"; // Remove shadow
                        }}
                      >
                        <img src="/Assets/Images/7thCenturyFANSCommunity.png" alt="7th Century Music Community"
                          style={{ width: "100%", borderRadius: "8px", transition: "transform 0.3s ease" }} />
                        <p style={{ marginTop: "10px", fontWeight: "bold" }}>7th Century Music Community</p>
                      </div>
                    </div>
                  </div>

                  {/* Course Progress Section (unchanged) */}
                  <div className="course-progress" style={{
                    marginTop: "20px",
                    backgroundColor: "#f9fafb",
                    borderRadius: "8px",
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}>
                    <div style={{ flex: 1 }}>
                      <h2 style={{ margin: 0 }}>Course Progress</h2>
                      <p style={{ margin: 0, color: "#6b7280" }}> {/* Gray text color */}
                        You marked 0/15 in course
                        <span style={{ color: "#1877f2" }}> {/* Blue text color */}
                          "7th Century Music - LEARNING HUB & Resources"
                        </span>
                      </p>
                      <button className="complete-course" style={{
                        backgroundColor: "#1877f2",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "10px 20px",
                        marginTop: "20px",
                        cursor: "pointer",
                        width: "fit-content"
                      }}
                        onClick={() => window.open("https://7thcenturymusic.app.clientclub.net/home", "_blank")}>
                        Complete Course
                      </button>
                    </div>

                    <div className="circular-progress" style={{
                      width: '120px',
                      height: '120px',
                      borderRadius: '50%',
                      background: `conic-gradient(#1877f2 0deg, #e4e6eb 0deg)`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative'
                    }}>
                      <div style={{
                        width: '100px',
                        height: '100px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        color: '#1877f2' // Match the blue color from the design
                      }}>
                        0%
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </main>
        </div>
      </div>

      {forgotModal ? (
        <Forgotpassword
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}

      {loginModal ? (
        <Userlogin
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}

      {signupModal ? (
        <UserSignup
          setSignupModal={setSignupModal}
          setLoginModal={setLoginModal}
          setForgotModal={setForgotModal}
        />
      ) : null}
      {loading && <LoadingBalls />}
    </>
  );
}
